<template>
  <div class="appointmentList-view">
    <div class="d-flex align-items-center mb20 mt70">
      <div class="fs7 fwbold cA1 w300 ">考核规则名称:</div>
      <div class="fs7 cA1 w300 ">{{detailData.name}}</div> 
    </div>
    <div class="d-flex align-items-center mb20">
      <div class="fs7 fwbold cA1 w300 ">考核时间周期:</div>
      <div class="fs7 cA1 w300 ">{{detailData.startTime}}  至 {{detailData.endTime}} </div>
    </div>
    <div class="d-flex align-items-center mb20">
      <div class="fs7 fwbold cA1 w300 ">考核状态:</div>
      <div class="fs7 cA1 w300 ">
        {{detailData.endTime == 1 ? '未开始' : detailData.endTime == 2 ? '进行中' : detailData.endTime == 3 ? '已结束' : ''}} 
      </div>
    </div>
    <div class="d-flex mb20">
      <div class="fs7 fwbold cA1 w300 ">考核内容:</div>
      <div class="fs7 cA1 w300">
        <div v-for="(item,index) in detailData.rules" :key="index">
          <div class="d-flex align-items-center" v-if="item.type == 1">
            <div class="w200">添加客户数</div>
            <div>{{item.value}}个</div>
          </div>
          <div class="d-flex align-items-center" v-if="item.type == 2">
            <div class="w200">新用户访问数</div>
            <div>{{item.value}}个</div>
          </div>
          <div class="d-flex align-items-center" v-if="item.type == 3">
            <div class="w200">客户跟进率</div>
            <div>{{item.value}}%</div>
          </div>
          <div class="d-flex align-items-center" v-if="item.type == 4">
            <div class="w200">商城销售金额</div>
            <div>{{item.value}}元</div>
          </div>
        </div>  
      </div>
    </div>

    <div class="d-flex align-items-center mb20">
      <div class="fs7 fwbold cA1 w300 ">考核达标率:</div>
      <div class="fs7 cA1 w300 ">{{detailData.process}}%</div>
    </div>
    <span class="cutOffRule"></span>
    <div class="d-flex align-items-center mt20">
      <div class="fs7 fwbold cA1 w300 ">考核情况
        <el-tooltip
          class="item"
          effect="dark"
          content='可通过输入或选择考核对象名称查看相应成员的考核详情。'
          placement="top"
        >
          <i style="font-size: 16px" class="el-icon-question" />
        </el-tooltip>
      </div>
      
    </div>

    <div class="d-flex flex-column">
      <div class="ml300 w500 d-flex justify-content-between mt50">
        <el-select
          v-model="currentMember"
          style="width: 150px;"
          filterable
          placeholder="请选择"
        >
          <el-option
            v-for="(item) in checkedRules"
            :key="item.empId"
            :label="item.nickeName"
            :value="item.empId"
          />
        </el-select>
        <el-button
          class="v-primary"
          type="primary"
          @click="queryEmployeeKpiUser"
          >搜索</el-button>
      </div>
      <p class="ml300 cA4 fs7 mt10">注：默认只展示排名前三位员工的数据，其他成员请搜索查询</p>
      <div class="projectCost">
        <div class="container">
          <div class="wrapper" v-for="(item,index) in list" :key="index"> 
            <div class="roseChart"></div> 
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  queryAdvancedFormById,
  addAdvancedForm,
  updateAdvancedForm,
} from "@/api/companyManage.js";
import { getDataTimeSec } from "@/utils";
import {
  getEmployeeKpiDetails,
  getEmployeeKpiUser
} from "@/api/employee.js";

export default {
  name: "appointmentList",
  data() {
    return {
      currentMember:'',//选中的成员
      checkedRules:[
      ],
      list:[ {},{},{}],
      detailData:'',
      kpiId:'',
    };
  },
  created() {
   if(this.$route.query.id){
      this.kpiId = this.$route.query.id
      this.queryEmployeeKpiDetails()
    }
  },
  
  methods: {
    drawRose(){
      let than = this
      var echarts = than.$echarts
      var roseCharts = document.getElementsByClassName('roseChart'); // 对应地使用ByClassName
      
      for(var i = 0;i < roseCharts.length;i++ ){ // 通过for循环，在相同class的dom内绘制元素
        var myChart = echarts.init(roseCharts[i]);
        myChart.setOption({
          title:{
           text:than.list[i].name,
           subtext:'已完成' + than.list[i].process + '%',
           right:'right'
          },
          tooltip: {
            rigger: 'item',
            formatter:'{c}%'　　　　//这是关键，在需要的地方加上就行了
          },
          xAxis: {
            type: 'category',
            data: ['添加客户数', '新用户访问数', '客户跟进率', '商城销售金额']
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              data: [{
                value: than.list[i].rules[0].process,
                itemStyle: {
                  color: '#6666FF'
                }
              }, {
                value: than.list[i].rules[1].process,
                itemStyle: {
                  color: '#FFCC00'
                }
              }, {
                value: than.list[i].rules[2].process,
                itemStyle: {
                  color: '#9933CC'
                }
              }, {
                value: than.list[i].rules[3].process,
                itemStyle: {
                  color: '#51CBCD'
                }
              }],
              type: 'bar',
              label: {
                  show: true,
                  position: 'top',
                  formatter: '{b}\n{c}%'　　　　//这是关键，在需要的地方加上就行了
              },
              
            }
          ]
      	})
      }
    },
    queryEmployeeKpiDetails(){
      let than = this
      let data = {
        kpiId:than.kpiId
      }
      getEmployeeKpiDetails(data)
      .then((res)=>{
        this.detailData = res.data.employee_kpi
        than.list =  res.data.user_process
        res.data.employee_kpi.employees.forEach((item,index) => {
          than.list.forEach((v,k)=>{
            if(item.empId == v.empId){
              v.name = item.nickeName
            }
          })
        })
        this.checkedRules = res.data.employee_kpi.employees
        //-console.log(than.list)
        this.drawRose()
      })
    },
    queryEmployeeKpiUser(){
      let than = this
      if(!this.currentMember){
        return this.$message.error('请先选择员工')
      }
      let data = {
        kpiId:this.kpiId,
        empId:this.currentMember
      }
      getEmployeeKpiUser(data)
      .then(res=>{
        if(res.data){
          than.list = [
            res.data
          ]
          than.checkedRules.forEach((item,index) => {
            than.list.forEach((v,k)=>{
              if(item.empId == v.empId){
                v.name = item.nickeName
              }
              

            })
            
          })
          this.drawRose()
        }
          
      })
    }
  },
  
  mounted(){
    
  }, 
};
</script>

<style lang="scss" scoped>
.appointmentList-view{
  background: #ffffff;
  padding: 24px;
}
.cutOffRule{
  display: block;
  width: 100%;
  height: 1px;
  background: #b8b6b6;
}
.projectCost{
  margin-top: 50px;
  margin-left: 300px;
}
.container{
  display: flex;
  flex-direction: column;
  width: 680px;
  background-size: 100% 100%;
}
.wrapper{
  margin-top: 20px;
}
.roseChart{
  width: 500px;
  height:400px;
}
</style>