import httpUtil from "@/utils/httpUtil";

/** 获取公司信息 */

export function fetchCompany(params, type) {
    return httpUtil.post("/api/crmPc/companyGoods/getCompany", params, type)
}

/** 更新公司信息 */

export function updCompany(params, type) {
    return httpUtil.post("/api/crmPc/companyGoods/updCompany", params, type)
}


/** 获取公司信息 */

export function fetchIndustryList(params, type) {
    return httpUtil.get("/api/crmPc/companyGoods/getList", params, type)
}


/** 获取公司成员 */
export function fetchCorporator(params, type) {
    return httpUtil.post("/api/crmPc/customer/getCompanyStaffList", params, type)
}

/** 搜索员工 */
export function fetchStaffList(params, type) {
    return httpUtil.post("/api/crmPc/customer/getCompanyStaffList", params, type)
}

/** 获取预约分类 */

export function fetchProductsTypeList(params, type) {
    return httpUtil.post("/api/crmPc/products/selectProductsTypeList", params, type)
}

/** 获取预约列表 */

export function fetchAppointmentPage(params, type) {
    return httpUtil.post("/api/crmPc/products/selectAppointmentPage", params, type)
}


/** 修改订单状态 */
export function alterAppointmentState(params, type) {
    return httpUtil.post("/api/crmPc/products/updAppointmentState", params, type)
}


/** 查询预约商品列表状态 */
export function fetchAppointmentGoodsList(params, type) {
    return httpUtil.post("/api/crmPc/products/selectProductsPage", params, type)
}

/** 修改商品排序 */
export function alterAppointmentGoodsSort(params, type) {
    return httpUtil.post("/api/crmPc/products/moveProducts", params, type)
}


/** 查询分类 */
export function fetchProductsTypePage(params, type) {
    return httpUtil.post("/api/crmPc/products/selectProductsTypePage", params, type)
}

/** 修改分类排序 */
export function alterAppointmentGoodsTypeSort(params, type) {
    return httpUtil.post("/api/crmPc/products/moveProductsType", params, type)
}

/** 添加分类 */
export function addAppointmentGoodsType(params, type) {
    return httpUtil.post("/api/crmPc/products/insertProductsType", params, type)
}


/** 删除分类 */
export function delProductsType(params, type) {
    return httpUtil.post("/api/crmPc/products/delProductsType", params, type)
}


/** 获取广告图分类 */
export function fetchPhotoType(params, type) {
    return httpUtil.post("/api/crmPc/companyPhoto/selectTypeNum", params, type)
}

/** 获取高级表单 */
export function queryPageAdvancedForm(params, type) {
    return httpUtil.post("/api/crmPc/advancedForm/queryPageAdvancedForm", params, type)
}



/** 获取高级表单详情 */
export function queryAdvancedFormById(params, type) {
    return httpUtil.post("/api/crmPc/advancedForm/queryAdvancedFormById", params, type)
}


/** 新增高级表单 */
export function addAdvancedForm(params, type) {
    return httpUtil.post("/api/crmPc/advancedForm/addAdvancedForm", params, type)
}

/** 编辑高级表单 */
export function updateAdvancedForm(params, type) {
    return httpUtil.post("/api/crmPc/advancedForm/updateAdvancedForm", params, type)
}
/** 编辑高级表单基础设置 */
export function updateSetting(params, type) {
    return httpUtil.post("/api/crmPc/advancedForm/updateSetting", params, type)
}
/** 查询高级表单基础设置 */
export function querySetting(params, type) {
    return httpUtil.post("/api/crmPc/advancedForm/querySetting", params, type)
}


/** 添加活动 */
export function addActivityEvent(params, type) {
    return httpUtil.post("/api/crmPc/activityEvent/addActivityEvent", params, type)
}

/** 更新活动 */
export function activityEventUpdate(params, type) {
    return httpUtil.post("/api/crmPc/activityEvent/activityEventUpdate", params, type)
}

/** 活动列表 */
export function activityEventList(params, type) {
    return httpUtil.post("/api/crmPc/activityEvent/activityEventList", params, type)
}

/** 删除活动 */
export function activityEventDelete(params, type) {
    return httpUtil.post("/api/crmPc/activityEvent/activityEventDelete", params, type)
}

/** 查询活动详情 */
export function activityEventDetails(params, type) {
    return httpUtil.post("/api/crmPc/activityEvent/activityEventDetails", params, type)
}

/** 报名列表 */
export function activityRegisterList(params, type) {
    return httpUtil.post("/api/crmPc/activityEvent/activityRegisterList", params, type)
}
/** 获取活动表单列表 */
export function activityForms(params, type) {
    return httpUtil.post("/api/crmPc/activityEvent/activityForms", params, type)
}

/** 退出活动 */
export function activityExit(params, type) {
    return httpUtil.post("/api/crmPc/activityEvent/activityExit", params, type)
}

/** 修改基础模块设置 */
export function updateBaseSetting(params, type) {
    return httpUtil.post("/api/crmPc/activityEvent/updateBaseSetting", params, type)
}

/** 查询基础模块设置 */
export function queryBaseSetting(params, type) {
    return httpUtil.post("/api/crmPc/activityEvent/queryBaseSetting", params, type)
}





/** 更改表单状态 */
export function updateAdvancedFormStatus(params, type) {
    return httpUtil.post("/api/crmPc/advancedForm/updateAdvancedFormStatus", params, type)
}

/** 获取高级表单用户数据 */
export function queryUserCommitDetail(params, type) {
    return httpUtil.post("/api/crmPc/advancedForm/queryUserCommitDetail", params, type)
}

/** 获取成员信息*/
export function getCompanyStaffInfo(params, type) {
    return httpUtil.post("/api/crmPc/businessCard/getCompanyStaffInfo", params, type)
}

/** 删除成员*/
export function quitCompany(params, type) {
    return httpUtil.post("/api/crmPc/businessCard/quitCompany", params, type)
}

/**删除商品信息 */
export const delProducts = params => httpUtil.post("/api/crmPc/products/delProducts", params)



// /** 获取高级表单用户数据 */
// export function queryUserCommitDetail(params,type) {
// 	return httpUtil.post("/api/crmPc/advancedForm/queryUserCommitDetail", params, type)
// }

/**设置部门 */
/**查询所有员工 */
export const queryPageEmployee = async params => httpUtil.post("/api/crmPc/department/queryPageEmployee", params);

/**查询不在部门的员工 */
export const queryNotDepart = async params => httpUtil.post("/api/crmPc/department/queryNotDepart", params);


/**添加部门 */
export const addDepartment = async params => httpUtil.post("/api/crmPc/department/addDepartment", params);

/**查询部门 */
export const queryPageDepartment = async params => httpUtil.post("/api/crmPc/department/queryPageDepartment", params);

/**查询部门详情 */
export const queryDepartment = async params => httpUtil.post("/api/crmPc/department/queryDepartment", params);


/**编辑部门成员 */
export const updateDepartment = async params => httpUtil.post("/api/crmPc/department/updateDepartment", params);

/**解散部门 */
export const disbandDepartment = async params => httpUtil.post("/api/crmPc/department/disbandDepartment", params);

/**删除部门 */
export const deleteDepartment = async params => httpUtil.post("/api/crmPc/department/deleteDepartment", params);






/**设置管理员 */
/** 保存管理员*/
export const addManager = async params => httpUtil.post("/api/crmPc/department/addManager", params);

/** 修改管理员*/
export const updateManager = async params => httpUtil.post("/api/crmPc/department/updateManager", params);

/** 查询员工权限 */
export const queryEmpRouter = async params => httpUtil.post("/api/crmPc/department/queryEmpRouter", params);

/** 员工详情查询 */
export const queryEmployee = async params => httpUtil.post("/api/crmPc/department/queryEmployee", params);

/**查询管理员列表 */
export const queryPageManager = async params => httpUtil.post("/api/crmPc/department/queryPageManager", params);

/**删除管理员 */
export const deletedManager = async params => httpUtil.post("/api/crmPc/department/deletedManager", params);

/**修改员工 */
export const updateEmployee = async params => httpUtil.post("/api/crmPc/department/updateEmployee", params);

/**更换boss */
export const changeBoss = async params => httpUtil.post("/api/crmPc/companyGoods/changeBoss", params);
/**
 * 更换boss前的验证手机号
 * @param params
 * @returns {Promise<*>}
 */
export const checkSms = async params => httpUtil.post("/api/crmPc/companyGoods/checkSms", params);

/**
 * 查询不包含自己的员工
 * @param params
 * @returns {Promise<*>}
 */
export const queryNotSelf = async params => httpUtil.post("/api/crmPc/companyGoods/queryNotSelf", params);

/**
 * 获取活动标签列表
 * @param params
 * @returns {Promise<*>}
 */
export const getActivityEventLabel = async(params = {}) => { return httpUtil.post("/api/crmPc/activityEvent/getActivityEventLabel", params) };

/**
 * 获得活动赞助信息列表
 * @param params
 * @returns {Promise<*>}
 */
export const getActivityEventSupportList = async(params = {}) => { return httpUtil.post("/api/crmPc/activityEvent/getActivityEventSupportList", params) };

/**
 * 添加修改活动赞助信息
 * @param params
 * @returns {Promise<*>}
 */
export const setActivityEventSupport = async(params = {}) => { return httpUtil.post("/api/crmPc/activityEvent/setActivityEventSupport", params) };

/**字典 */
export const getByDictType = async params => httpUtil.post("/api/crmPc/sysDict/getByDictType", params);
/**导出活动详情 */
export const outPutActivityRegisterList = async params => httpUtil.post("/api/crmPc/activityEvent/outPutActivityRegisterList", params);